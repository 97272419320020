<template>
  <div class="info">
    <AppText
      variant="div"
      size="22px"
      class="font-medium"
      mt="20px"
    >
      {{ info.title }}
    </AppText>

    <AppText
      variant="div"
      size="15px"
      :line-height="1.5"
      mt="24px"
      mb="50px"
      align="center"
      style="width: 340px;"
    >
      {{ info.description }}
    </AppText>

    <FButton :to="info.link">
      {{ info.buttonText }}
    </FButton>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { i18n } from '@/plugins/localization';
import { useTemporaryStore } from '@/store';

export default {
  name: 'SessionExpired',
  setup() {
    const route = useRoute();
    const { t } = i18n.global;

    const info = computed(() => {
      if (route.params.message === 'AUTH_CODE_USED') {
        return {
          title: t('signup.invitation.linkExpired.title'),
          description: t('signup.invitation.linkExpired.description'),
          buttonText: t('signup.invitation.linkExpired.button'),
          link: '/',
        };
      }

      return {
        title: t('signup.invitation.sessionExpired.title'),
        description: t('signup.invitation.sessionExpired.description'),
        buttonText: t('signup.invitation.sessionExpired.button'),
        link: '/sign-up',
      };
    });

    const { store } = useTemporaryStore();
    store.clearRegistrationState();

    return { info };
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 31px 0 53px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
